<template>
  <Details
    title="Customer"
    resource="customers"
    base-path="customers.index"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel>
        <template v-slot:heading>customer details</template>
        <template v-slot:text>Edit the basic information about customer, including required fields.</template>
      </DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <CustomerForm
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import Details from '@/components/auth/details/Details';
  import CustomerForm from '@/views/auth/customers/CustomerForm';
  import ModalNavigation from '@/mixins/ModalNavigation';
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";

  export default {
    name: 'CustomersCreate',
    components: {
      CustomerForm,
      Details,
      DetailsSidePanel
    },
    mixins: [ModalNavigation],
  };
</script>
